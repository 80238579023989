@import "../../styles/_chunk";

.NonGameBanner {
  background: linear-gradient(#104a8b, #3f7bbe);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-up(md) {
    min-height: 170px;
    flex-direction: row;
  }

  @include media-breakpoint-up(lg) {
    min-height: 190px;
  }

  @include media-breakpoint-up(xl) {
    min-height: 210px;
  }

  @include media-breakpoint-up(xxl) {
    min-height: 250px;
  }

  .NonGameBanner__coverContainer {
    width: 100%;
    padding: 1.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @include media-breakpoint-up(sm) {
      padding: 2.5rem;
      padding-bottom: 1rem;
    }

    @include media-breakpoint-up(md) {
      width: 35%;
      padding: 1.5rem;
      padding-right: 0.75rem;
    }

    @include media-breakpoint-up(lg) {
      width: 30%;
      padding: 1.5rem 2rem;
      padding-right: 1rem;
    }

    @include media-breakpoint-up(xl) {
      padding: 1.5rem 2.5rem;
      padding-right: 1.25rem;
    }

    @include media-breakpoint-up(xxl) {
      padding: 2rem 3rem;
      padding-right: 1.5rem;
    }
  }

  .NonGameBanner__detailsContainer {
    width: 100%;
    padding: 1.5rem;
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      padding: 2.5rem;
      padding-top: 1rem;
    }

    @include media-breakpoint-up(md) {
      width: 65%;
      padding: 1.5rem;
      padding-left: 0.75rem;
    }

    @include media-breakpoint-up(lg) {
      width: 70%;
      padding: 1.5rem 2rem;
      padding-left: 1rem;
    }

    @include media-breakpoint-up(xl) {
      padding: 1.5rem 2.5rem;
      padding-left: 1.25rem;
    }

    @include media-breakpoint-up(xxl) {
      padding: 2rem 3rem;
      padding-left: 1.5rem;
    }

    .NonGameBanner__mainText {
      font-weight: 700;
      color: $text-white;
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(sm) {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.75rem;
        line-height: 2.25rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 2.125rem;
        line-height: 2.5rem;
        margin-bottom: 0.75rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 2.25rem;
        line-height: 2.75rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 2.75rem;
        line-height: 3.25rem;
      }
    }

    .NonGameBanner__subText {
      font-weight: 400;
      color: $text-white;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;

      @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
        line-height: 1.125rem;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 1.25rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }

    .NonGameBanner__buttonContainer {
      display: flex;

      .NonGameBanner__button {
        background-color: $white;
        color: $text-black;
        border-radius: $border-radius-sm;
        padding: 0.375rem 1.125rem;
        min-height: 40px;
        min-width: 200px;
        text-align: center;
        font-weight: 700;
        font-size: 0.9375rem;

        &:hover {
          background-color: $grey-2;
        }

        @include media-breakpoint-only(md) {
          min-height: 36px;
        }

        @include media-breakpoint-up(xl) {
          padding: 0.5rem 1.5rem;
          font-size: 1rem;
        }
      }
    }
  }

  .NonGameBanner__background {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-top: 1rem;
    padding-right: 1rem;
    opacity: 0.1;

    @include media-breakpoint-up(lg) {
      opacity: 0.6;
    }

    @include media-breakpoint-up(xl) {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom;
    }
  }
}
